/* ==========================================================================
   6. Comments
   ========================================================================== */

.comments-area {
  border-bottom: 1px solid _palette(border);
  padding: 1.66667em 0 3.33333em;
}

.comments-title {
  font-size: 1.33333rem;

  @media only screen and (max-width: 600px) {
    font-size: 1.25rem;
  }
}