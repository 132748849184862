.inner {
    max-width: 780px;
}
li.menu-item {
    margin-left: .5em;
}
.menu-item a {
    font-weight: normal;
}
a.logo-text {
    color: #717687;
}
.post-title {
    margin: 0 0 .5rem;
}
.post-subtitle {
    margin: 0 0 1rem;
    opacity: 0.6;
    font-weight: 300;
}

blockquote {
    border-left: 2px solid #ddd;
}

a {
    color: navy;
}
a.appstore {
    text-decoration: none;
    background: url(/images/appstore.svg) no-repeat transparent;
    background-size: 100%;
    display: inline-block;
    width: 184px;
    height: 56px;
    margin-right: .25em;
    vertical-align: middle;
}

a.appstore-mac {
    text-decoration: none;
    background: url(/images/appstore-mac.svg) no-repeat transparent;
    background-size: 100%;
    display: inline-block;
    width: 220px;
    height: 56px;
    margin-right: .15em;
}